/**
 * Application entry point
 */

// Load application styles
import 'styles/reset.css'
import 'styles/index.scss'
import GyroNorm from 'gyronorm/dist/gyronorm.complete';
import { TweenLite, TimelineLite } from "gsap/TweenMax"
import cloud1Src from '../assets/images/cloud1.png'
import cloud2Src from '../assets/images/cloud2.png'
import cloud3Src from '../assets/images/cloud3.png'
import cloud4Src from '../assets/images/cloud4.png'
const plugins = [TimelineLite]
const isMobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)


function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min
}

function setUpCloud(getClientW, getClientH) {
    const cloudLayer = document.querySelector('.cloud-layer')
    function makeCloud(src) {
        const cloud = document.createElement('IMG')
        cloud.addEventListener('load', () => {
            cloudLayer.appendChild(cloud)
            function randomFloat() {
                const scale = getRandomArbitrary(.8, 5)
                const timeDelta = getRandomArbitrary(-5, 5)
                const top0 = getRandomArbitrary(-cloud.clientHeight, getClientH() / 5)
                const top1 = getRandomArbitrary(-cloud.clientHeight, getClientH() / 5)
                const opacity0 = getRandomArbitrary(.5, 1)
                const opacity1 = getRandomArbitrary(.5, 1)

                const newWidth = scale * cloud.clientWidth
                cloud.style.width = `${newWidth}px`
                TweenLite.fromTo(cloud, 30 + timeDelta,
                    { left: `-${newWidth}px`, top: `${top0}px`, opacity: opacity0 },
                    { left: `${getClientW()}px`, top: `${top1}px`, opacity: opacity1, onComplete: randomFloat })
            }
            randomFloat()
        })
        cloud.setAttribute('src', src)
    }
    setTimeout(() => {
        makeCloud(cloud1Src)
    }, 0)
    setTimeout(() => {
        makeCloud(cloud4Src)
    }, 10000)
    setTimeout(() => {
        makeCloud(cloud3Src)
    }, 20000)
    setTimeout(() => {
        makeCloud(cloud2Src)
    }, 30000)
}

function runTextScript() {
    // prepare elements
    const theContainer = document.querySelector("#impression .container")
    const theHr = document.querySelector("#impression .container hr")
    const theName = document.querySelectorAll("#impression .container .name > *")
    const theInfo = document.querySelectorAll("#impression .container .about > *")
    // the script
    let tl = new TimelineLite({ paused: true })
    tl.fromTo(theHr, .4, { width: '0' }, { width: '20em' })
    tl.from(theName, 1, { y: 240 }, '-=0.2')
    tl.from(theInfo, 1, { y: -240 }, '-=1.2')
    tl.to(theHr, .4, { width: '11em' })
    // requestAnimationFrame(()=>{
    //     theContainer.style.visibility = 'unset'
    //     requestAnimationFrame(()=>{
    //         tl.play()
    //     })
    // })
    setTimeout(() => {
        theContainer.style.visibility = 'unset'
        setTimeout(() => {
            tl.play()
        })
    })
}

document.addEventListener('DOMContentLoaded', () => {
    let clientW = document.documentElement.clientWidth
    let clientH = document.documentElement.clientHeight
    window.addEventListener("resize", () => {
        clientW = document.documentElement.clientWidth
        clientH = document.documentElement.clientHeight
    })
    const bgDiv = document.querySelector("#photo-bg")

    if (isMobile) {
        const gn = new GyroNorm()
        gn.init().then(() => {
            gn.start(data => {
    
                const gamma = (data.do.gamma)
                const beta = (data.do.beta)
                if(beta > 90 || beta < -90) return;
    
                let ry = gamma / 30
                let rx = beta / 15
                let tx = gamma / 5
                let ty = beta / 5
                const style = `rotateX(${rx}deg) rotateY(${ry}deg) translate(${tx}px, ${ty}px)  translateZ(-100px)`
                bgDiv.style.transform = style;
                bgDiv.style.webkitTransform = style;
                bgDiv.style.mozTransform = style;
                bgDiv.style.msTransform = style;
                bgDiv.style.oTransform = style;
            })
        }).catch( e => {
    
        })
    } else {
        const XDEG = 6, YDEG = 3, XTRAN = 16, YTRAN = 12
        document.body.addEventListener('mousemove', (e) => {
            const deltaX = 1 - 2 * e.clientX / clientW
            const deltaY = 1 - 2 * e.clientY / clientH
            console.log(deltaX)
            console.log(deltaY)
            // rotatY -8deg -> 8deg
            let ry = - YDEG * deltaX
            let rx = XDEG * deltaY
            let tx = XTRAN * deltaX
            let ty = YTRAN * deltaY
            console.log(rx)
            console.log(ry)
            // const style = "rotateX(" + rx + "deg) rotateY(" + ry + "deg) translate("+tx+"rem)";
            const style = `rotateX(${rx}deg) rotateY(${ry}deg) translate(${tx}px, ${ty}px) translateZ(-100px)`
            bgDiv.style.transform = style;
            bgDiv.style.webkitTransform = style;
            bgDiv.style.mozTransform = style;
            bgDiv.style.msTransform = style;
            bgDiv.style.oTransform = style;
            // h1 move
            const transformH1 = `translate(${tx / 2}px, ${ty / 2}px)`
            const SHADOWCOLOR = '#333'
            const textShadowH1 = `0px 0px 10px white,
                                ${deltaX * 2}px ${deltaY * 2}px ${SHADOWCOLOR},
                                ${deltaX * 3}px ${deltaY * 3}px ${SHADOWCOLOR},
                                ${deltaX * 4}px ${deltaY * 4}px ${SHADOWCOLOR},
                                ${deltaX * 5}px ${deltaY * 5}px ${SHADOWCOLOR},
                                ${deltaX * 6}px ${deltaY * 6}px ${SHADOWCOLOR},
                                ${deltaX * 7}px ${deltaY * 7}px ${SHADOWCOLOR}`
            // theImp.style.transform = transformH1
            // theH1.style.textShadow = textShadowH1
        })
    }
    runTextScript()
})